export default () => {
  const user = useUserStore()
  const userFeatures = computed(() => user.readable?.userInfo?.feature_flags || [])
  const tenantFeatures = computed(() => currentTenant.value?.feature_flags || [])
  const hasAccessToIntegrations = computed(() => userFeatures.value.includes('integrations_gui'))
  const currentTenant = computed(() => user?.currentTenantInfo)
  const hasSeatGUI = computed(() => {
    return tenantFeatures.value.includes('seat_management') && (user.readable?.userInfo?.is_superuser || user.readable.isAdminOrOwner)
  })
  const hasCalendarAccess = computed(() =>
    tenantFeatures.value.includes('nylas_integration')
  )
  const hasSharedTemplatesAccess = computed(() => {
    return tenantFeatures.value.includes('account_templates') && (user.readable?.userInfo?.is_superuser || user.readable.isAdminOrOwner)
  })
  const hasAccessToWhatsapp = computed(() => {
    return user.readable?.phoneMenus.some(menu => menu.enable_whatsapp)
  })
  const globalRoutes = computed(() => {
    const menus = []
    if (currentTenant.value) {
      menus.push({
        to: hasCalendarAccess.value ? '/calendar' : '/early-access/calendar',
        title: 'Calendar Integration',
        icon: 'mdi-calendar-sync',
        description: 'The calendar integration allows you to configure your calendar from Emissary.',
        hasNotification: false,
        showInHome: true
      })
      if (!hasAccessToWhatsapp.value) {
        menus.push({
          to: '/early-access/whatsapp-messaging',
          title: 'WhatsApp',
          icon: 'mdi-whatsapp',
          description: 'Connect with candidates and employees around the world with WhatsApp Business, fully integrated with Emissary.',
          hasNotification: false,
          showInHome: true
        })
      }
      menus.push({
        to: `/metrics/messages-campaigns/${currentTenant.value.id}/`,
        title: 'Metrics',
        icon: 'mdi-chart-bar',
        description: 'Here you can see interactions between seats and their contacts.',
        hasNotification: false,
        showInHome: true
      })
      if (hasAccessToIntegrations.value) {
        menus.push({
          to: `/integrations/${currentTenant.value.id}`,
          title: 'Integrations',
          icon: 'mdi-cogs',
          description: 'The integrations page helps you to manage your integrations with external systems.',
          hasNotification: false,
          showInHome: true
        })
      }
      if (hasSeatGUI.value) {
        menus.push({
          to: `/seats/${currentTenant.value.id}`,
          title: 'Manage Users',
          icon: 'mdi-account-group',
          description: 'Here you will be able to manage your users and their permissions.',
          hasNotification: false,
          showInHome: true
        })
      }
      if (hasSharedTemplatesAccess.value) {
        menus.push({
          to: `/shared-templates/${currentTenant.value.id}`,
          title: 'Shared Templates',
          icon: 'mdi-note-text-outline',
          description: 'Here you will be able to manage shared templates.',
          hasNotification: false,
          showInHome: true
        })
      }
    }
    return menus
  })
  return {
    globalRoutes,
    hasAccessToIntegrations,
    hasSeatGUI,
    hasCalendarAccess,
    currentTenant,
    userFeatures,
    tenantFeatures,
    hasSharedTemplatesAccess
  }
}
